import React, { Component } from 'react';
import Color from "../Color";
import Pqrs from "../Pqrs";
import Comercial from "../Comercial";

import Cliente from '../Cliente';
import Dashboard from '../dashboard';

import Empresa from '../Empresa';
import EmpresaResolucion from '../EmpresaResolucion';
import Sucursal from '../Sucursal';
import Grupo from '../Grupo';
import Usuario from '../Usuario';
import RemitenteDestinatario from '../RemitenteDestinatario';

import ZonaCiudad from '../ZonaCiudad';
import UnidadNegocio from '../UnidadNegocio';
import UnidadResidencial from '../UnidadResidencial';

import Municipio from '../Municipio';
import LineaNegocio from '../LineaNegocio';
import Empaque from '../Empaque';

import Departamento from '../Departamento';
import ConfiguracionVehiculo from '../ConfiguracionVehiculo';
import Aseguradora from '../Aseguradora';

import Ciudad from '../Ciudad';
import ClaseLinea from '../ClaseLinea';
import MarcaVehiculo from '../MarcaVehiculo';
import Barrio from '../Barrio';

import Carroceria from '../Carroceria';
import ActividadEconomica from '../ActividadEconomica';

import Trailer from '../Trailer';
import Tercero from '../Tercero';
import Vehiculo from '../Vehiculo';

import EntidadBancaria from '../EntidadBancaria';
import EntidadSalud from '../EntidadSalud';
import ProveedorGps from '../ProveedorGps';
import Pedido from '../Pedido';

import Manifiesto from '../Manifiesto';
import Ordencargue from '../Ordencargue';
import Factura from '../Factura';
import Remesa from '../Remesa';

import ListaEmpaque from '../ListaEmpaque';

import PedidoCumplido from '../PedidoCumplido';
import PedidoCumplidoVisor from '../PedidoCumplido/Procedimientos/Visor/index';

import Producto from '../Producto';
import Pais from '../Pais';
import MarcaTrailer from '../MarcaTrailer';

import Ruta from '../Ruta';

import Tarifa from '../Tarifa';

import FormDespacho from '../FormDespacho';
import EncuestaBodega from '../EncuestaBodega';
import EncuestaBodegaVisor from '../EncuestaBodega/Procedimientos/EncuestaBodegaVisor';
import InformeEncuestaBodega from '../EncuestaBodega/Informes/InformeEncuestaBodega';

import EncuestaPedido from '../EncuestaPedido';
import EncuestaPedidoVisor from '../EncuestaPedido/Procedimientos/EncuestaPedidoVisor/index';
import ReenviarEncuesta from '../EncuestaPedido/Procedimientos/ReenviarEncuesta';
import InformeEncuestaPedido from '../EncuestaPedido/Informes/InformeEncuestaPedido';


import AnulacionManifiesto from '../Manifiesto/Procedimientos/Anulacion';
import ReenvioMinisterio from '../Manifiesto/Procedimientos/ReenvioMinisterio';

import FacturaVisorDian from '../Factura/Procedimientos/Visor';

import ListaEmpaqueVisor from '../ListaEmpaque/Procedimientos/ListaEmpaqueVisor';
import PedidoVisor from '../Pedido/Procedimientos/Visor';
import PedidoVisorTrazabilidadVisitas from '../Pedido/Procedimientos/VisorTrazabilidadVisitas';
import PedidoRecalcular from '../Pedido/Procedimientos/PedidoRecalcular';
import VisorResolucionesFaltantes from '../Empresa/Procedimientos/VisorResolucionesFaltantes';

import PedidoVisorVisitas from '../Pedido/Procedimientos/VisorVisitas';

import InformePedidoOperativo from '../Pedido/Informes/InformePedidoOperativo';
import InformeGeneralTarifas from '../Tarifa/Informes/InformeGeneralTarifas';

import InformePedidoFinanciero from '../Pedido/Informes/InformePedidoFinanciero';
import InformePedidoVentas from '../Pedido/Informes/InformePedidoVentas';
import InformeLiquidacionAuxiliar from '../Pedido/Informes/InformeLiquidacionAuxiliar';
import InformeLiquidacionCeroEstres from '../Pedido/Informes/InformeLiquidacionCeroEstres';
import InformeRutaDesplazamientoLaboral from '../Pedido/Informes/InformeRutaDesplazamientoLaboral';
import InformeInspeccionPreoperativa from '../Pedido/Informes/InformeInspeccionPreoperativa';
import PedidoPagos from '../Pedido/Procedimientos/PedidoPagos';
import InformePedidoNovedad from '../Pedido/Informes/InformePedidoNovedad';
import InformeIndicadorTipoTarifa from '../Pedido/Informes/InformeIndicadorTipoTarifa';

import InformeIndicadorGestionComercialEstado from '../Pedido/Informes/InformeIndicadorGestionComercialEstado';
import InformeIndicadorComisionComercial from '../Pedido/Informes/InformeIndicadorComisionComercial';
import InformeIndicadorGestionComercialTipoTarifa from '../Pedido/Informes/InformeIndicadorGestionComercialTipoTarifa';
import InformeIndicadorGestionComercialVisita from '../Pedido/Informes/InformeIndicadorGestionComercialVisita';
import InformeIndicadorCubicajeBodega from '../Pedido/Informes/InformeIndicadorCubicajeBodega';

import InformeManifiestoGeneral from '../Manifiesto/Informes/InformeManifiestoGeneral';
import InformeManifiestoAseguradora from '../Manifiesto/Informes/InformeManifiestoAseguradora';

import FacturaConceptos from '../Factura/Procedimientos/FacturaConceptos';
import FacturaAutomatica from '../Factura/Procedimientos/FacturaAutomatica';
import InterfazContableFactura from '../Factura/Procedimientos/InterfazContable';

import FacturaAnular from '../Factura/Procedimientos/FacturaAnular';
import FacturaEnviarDian from '../Factura/Procedimientos/EnviarDian';
import FacturaAjusteAlPeso from '../Factura/Procedimientos/FacturaAjusteAlPeso';

import FacturaModificarResolucion from '../Factura/Procedimientos/FacturaModificarResolucion';

import InterfazContableManifiesto from '../Manifiesto/Procedimientos/InterfazContable';
import InformeGeneralClientes from '../Cliente/Informes/InformeGeneralClientes';

import CargarRemesas from '../Remesa/Procedimientos/CargarRemesas';
import IndicadoresGerenciales from '../Empresa/Procedimientos/IndicadoresGerenciales';
import MovimientoInventario from '../MovimientoInventario';
import InformeEstadoOcupacion from '../MovimientoInventario/Informes/InformeEstadoOcupacion';
import InformeBodegasNovedades from '../MovimientoInventario/Informes/InformeBodegasNovedades';
import InformeGeneralInventario from '../MovimientoInventario/Informes/InformeGeneralInventario';
import InformeKardexAlmacen from '../MovimientoInventario/Informes/InformeKardexAlmacen';
import InventarioVisor from '../MovimientoInventario/Procedimientos/InventarioVisor';

import InventarioActualizar from '../MovimientoInventario/Procedimientos/InventarioActualizar';
import InventarioTraslado from '../MovimientoInventario/Procedimientos/InventarioTraslado';
import VisorDisponibilidad from '../MovimientoInventario/Procedimientos/VisorDisponibilidad';
import InformeGeneralListaEmpaque from '../ListaEmpaque/Informes/InformeGeneralListaEmpaque';
import CuadroIndicadorContratacion from '../Manifiesto/Procedimientos/CuadroIndicadorContratacion';
import Almacen from '../Almacen';
import Bodega from '../Bodega';
import Estructura from '../Estructura';
import Ubicacion from '../Ubicacion';



class Loader extends Component {

  getComponentToRender = () => {
    const moduleName = this.props.match.params.module;
    switch (moduleName) {
      case 'color':
        return <Color />;
      case 'pqrs':
          return <Pqrs />;        
      case 'comercial':
        return <Comercial />;
      case 'cliente':
        return <Cliente />;
      case 'empresa':
        return <Empresa />;
      case 'empresaresoluciondetalle':
        return <EmpresaResolucion />;
      case 'sucursal':
        return <Sucursal />;
      case 'grupo':
        return <Grupo />;
      case 'remitentedestinatario':
        return <RemitenteDestinatario />;
      case 'usuario':
        return <Usuario />;
      case 'tercero':
        return <Tercero />;
      case 'vehiculo':
        return <Vehiculo />;
      case 'trailer':
        return <Trailer />;
      case 'barrio':
        return <Barrio />;
      case 'marcavehiculo':
        return <MarcaVehiculo />;
      case 'claselinea':
        return <ClaseLinea />;
      case 'ciudad':
        return <Ciudad />;
      case 'aseguradora':
        return <Aseguradora />;
      case 'configuracionvehiculo':
        return <ConfiguracionVehiculo />;
      case 'departamento':
        return <Departamento />;
      case 'empaque':
        return <Empaque />;
      case 'lineanegocio':
        return <LineaNegocio />;
      case 'municipio':
        return <Municipio />;
      case 'unidadresidencial':
        return <UnidadResidencial />;
      case 'unidadnegocio':
        return <UnidadNegocio />;
      case 'zonaciudad':
        return <ZonaCiudad />;
      case 'carroceria':
        return <Carroceria />;
      case 'actividadeconomica':
        return <ActividadEconomica />;
      case 'dashboard':
        return <Dashboard />;
      case 'entidadbancaria':
        return <EntidadBancaria />;
      case 'entidadsalud':
        return <EntidadSalud />;
      case 'proveedorgps':
        return <ProveedorGps />;
      case 'pedido':
        return <Pedido />;
      case 'producto':
        return <Producto />;
      case 'pais':
        return <Pais />;
      case 'marcatrailer':
        return <MarcaTrailer />;
      case 'manifiesto':
        return <Manifiesto />;
      case 'ordencargue':
        return <Ordencargue />;
      case 'factura':
        return <Factura />;
      case 'remesa':
        return <Remesa />;
      case 'lista_empaque':
          return <ListaEmpaque />;  
      case 'pedido_cumplido':
        return <PedidoCumplido />;            
      case 'formdespacho':
        return <FormDespacho />;
      case 'manifiesto_anulacion':
        return <AnulacionManifiesto />;
      case 'manifiesto_reenvio_ministerio':
        return <ReenvioMinisterio />;
      case 'informe_indicador_cubicaje_bodega':
        return <InformeIndicadorCubicajeBodega />;
        

      case 'pedido_cumplido_visor':
        return <PedidoCumplidoVisor toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;

      case 'factura_visor_dian':
        return <FacturaVisorDian toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />; 
      case 'pedido_visor':
        return <PedidoVisor toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;
      case 'empresa_visor_resoluciones_faltantes':
        return <VisorResolucionesFaltantes toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />; 
      case 'pedido_visor_visitas':
        return <PedidoVisorVisitas toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;
      case 'pedido_visor_trazabilidad_visitas':
          return <PedidoVisorTrazabilidadVisitas toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;             
      case 'lista_empaque_visor':
        return <ListaEmpaqueVisor toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;
        
      case 'encuesta_bodega':
        return <EncuestaBodega toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;
      case 'encuesta_bodega_visor':
        return <EncuestaBodegaVisor toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;
      case 'informe_encuesta_bodega':
        return <InformeEncuestaBodega  />;
          
      case 'encuesta_pedido':
        return <EncuestaPedido toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />; 
      case 'encuesta_pedido_visor':
        return <EncuestaPedidoVisor toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;
      case 'informe_encuesta_pedido':
        return <InformeEncuestaPedido  />;
      case 'reenviar_encuesta':
        return <ReenviarEncuesta />;
  
      case 'informe_pedido_operativo':
        return <InformePedidoOperativo />;

        case 'informe_general_tarifas':
          return <InformeGeneralTarifas />;

      case 'informe_pedido_financiero':
        return <InformePedidoFinanciero />;

      case 'pedido_pagos':
          return <PedidoPagos />;         

      case 'informe_pedido_ventas':
        return <InformePedidoVentas />;

      case 'informe_liquidacion_auxiliar':
        return <InformeLiquidacionAuxiliar />;

      case 'informe_liquidacion_cero_estres':
        return <InformeLiquidacionCeroEstres />;

      case 'informe_general_clientes':
        return <InformeGeneralClientes />;        

        case 'informe_pedido_novedad':
          return <InformePedidoNovedad />;        
      case 'informe_indicador_tipo_tarifa':
        return <InformeIndicadorTipoTarifa />;   
      case 'informe_indicador_gestion_comercial_estado':
        return <InformeIndicadorGestionComercialEstado />;
        case 'informe_indicador_comision_comercial':
          return <InformeIndicadorComisionComercial />;        
      case 'informe_indicador_gestion_comercial_tipo_tarifa':
        return <InformeIndicadorGestionComercialTipoTarifa />;  

      case 'informe_ruta_desplazamiento_laboral':
        return <InformeRutaDesplazamientoLaboral/>;  

      case 'informe_inspeccion_preoperativa':
        return <InformeInspeccionPreoperativa/>;          
      
      case 'informe_indicador_gestion_comercial_visita':
        return <InformeIndicadorGestionComercialVisita />; 

      case 'informe_manifiesto_general':
        return <InformeManifiestoGeneral />;
      case 'informe_manifiesto_aseguradora':
        return <InformeManifiestoAseguradora />;
     
      case 'factura_conceptos':
        return <FacturaConceptos />;

      case 'factura_ajuste_al_peso':
        return <FacturaAjusteAlPeso />;

      case 'factura_enviar_dian':
        return <FacturaEnviarDian />;

      case 'factura_anular':
        return <FacturaAnular />;
      case 'factura_modificar_resolucion':
        return <FacturaModificarResolucion />;

      case 'factura_automatica':
        return <FacturaAutomatica />;        
      case 'interfaz_contable_factura':
        return <InterfazContableFactura />;
      case 'interfaz_contable_manifiesto':
        return <InterfazContableManifiesto />;
      case 'cargar_remesas':
        return <CargarRemesas />
      case 'indicadores_gerenciales':
        return <IndicadoresGerenciales />
      case 'pedido_recalcular':
        return <PedidoRecalcular />
      case 'ruta':
        return <Ruta />;   
      case 'tarifa':
        return <Tarifa />;               
      case 'almacen':
        return <Almacen />;
      case 'bodega':
        return <Bodega />;
      case 'estructura':
        return <Estructura />;                      
      case 'ubicacion':
        return <Ubicacion />;
      case 'movimientoinventario':
        return <MovimientoInventario />;
      case 'informe_general_inventario':
        return <InformeGeneralInventario />;
      case 'informe_estado_ocupacion':
        return <InformeEstadoOcupacion />;      
      case 'informe_bodegas_novedades':
          return <InformeBodegasNovedades />;             
     case 'informe_kardex_almacen':
          return <InformeKardexAlmacen />;  

    case 'inventario_actualizar':
      return <InventarioActualizar />;            

    case 'visor_disponibilidad':
        return <VisorDisponibilidad toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;   

    case 'cuadro_indicador_contratacion':
      return <CuadroIndicadorContratacion toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />;   
      
    case 'inventario_traslado':
        return <InventarioTraslado />;   

     case 'informe_general_lista_empaque':
          return <InformeGeneralListaEmpaque />; 
   
    case 'inventario_visor':
      return <InventarioVisor toggleFavorites={this.props.toggleFavorites} toogleFavoritesShow={this.props.toogleFavoritesShow} />; 

      default:
        return null;

    }
  }

  render() {
    return (
      <div>
        {this.getComponentToRender()}
      </div>
    );
  }
}

export default Loader;