import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';
import Datagrid from '../GeneralUI/PDatagrid/'
import CustomizedExpansionPanel from './../GeneralUI/PExpansionpanels'
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';

class Factura extends Component {

  state = {
    fields: {
      facturaCodigo: null,
      
      factura_resolucion: null,
      clienteCodigo: null,
      sucursalCodigo: null,
      
      facturaFecha: null,
      facturaFechaVencimiento: null,
      comercialCodigo: null, 

      forpagCodigo: null,      
      facturaQrpago: null, 
      facturaDocumentoSoporte1: null, 

      facturaCufe: null, 
      facturaFechaDian: null, 
      facturaFirmaDigitalDian: null, 
          
      facturaEnviarALaDian: null,    
      facturaContabilidad: null, 
      facturaTipoFactura: null,       

      facturaTotalImpuestoResta: null,
      facturaTotalImpuestoSuma: null,
      facturaTotalImpuesto: null,

      facturaDescuento: null, 
      facturaCargo: null, 
      facturaValor: null,

      facturaObservacion: null,
      facturaEstado: null,
      empresaCodigo: null, 
       
      facturaFechaCreacion: null, 
      facturaFechaModificacion: null,                 
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    facturaSubtotal:0,    
    dataDatagridFactura: [{
      Sucursal: { 'value': null, 'key': null,'visible':true  },
      Tarifa: { 'value': null, 'key': null,'visible':true  },
      UnidadNegocio: { 'value': null, 'key': null,'visible':true  },
      Vendedor: { 'value': null, 'key': null,'visible':true  },
      Cantidad:{ 'value': null, 'key': null,'visible':true  } ,
      VUnitario: { 'value': null, 'key': null,'visible':true  } ,
      VTotal: { 'value': null, 'key': null,'visible':true  } ,
      NombreBodega: { 'value': null, 'key': null,'visible':true  },
      Cubicaje: { 'value': null, 'key': null,'visible':true  } ,
      DuracionBodega: { 'value': null, 'key': null,'visible':true  } ,
      ValorDeclarado:{ 'value': null, 'key': null,'visible':true  } ,
      Estado:{ 'value': null, 'key': null,'visible':false  }
    }],
    dataDatagridFacturaDocumentosAsociados: [{
      DocumentoAsociado: { 'value': null, 'key': null,'visible':true  },
      DocumentoAsociadoNombre: { 'value': null, 'key': null,'visible':true  },
      Imprimir: { 'value': null, 'key': null,'visible':true  },
    }],
    responseForm: { Message: '', typeMessage: '' }
  };

  async componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    await getDocument({
      documentId: params.id,
      fieldName: 'facturaCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

    await getDocument({
      documentId: params.id,
      fieldName: 'factura_codigo',
      moduleName: 'vw_detalle_factura_interfaz',
      isView: true,
      fields: ['codigo',
        'factura_codigo',
        'documento',
        'descripcion',
        'cantidad',
        'valor',
        'base_impuesto',
        'valor_impuesto',
        'descuento',
        'cargo',
        'tipo_tarifa'
      ],
      Limit: 50
    }).then(document => {
      if (!document) {
        return;
      }
      this.builddetailInterface(document);
    }).catch(ex => {
      console.log('index.jsx -- 137 > ex === ', ex);
    });

    await getDocument({
      documentId: params.id,
      fieldName: 'factura_codigo',
      moduleName: 'vw_detalle_factura_documentos_asociado_interfaz',
      isView: true,
      fields: ['pedido_codigo',
        'factura_codigo',
      'documento_nombre'],
      Limit: 50
    }).then(document => {
      if (!document) {
        return;
      }
      this.builddetailInterfaceDocumentosAsociados(document);
    }).catch(ex => {
      console.log('index.jsx -- 137 > ex === ', ex);
    });
    
    this.calcularSubtotal();
  }

  builddetailInterface = (data) => {

    let rows = data.map(row => {
      return {
        Codigo: { 'value': row.codigo, 'key': row.codigo, 'visible':true },
        Documento: { 'value': row.documento, 'key': row.documento, 'visible':true },
        Descripcion: { 'value': row.descripcion, 'key': row.descripcion, 'visible':true },
        Cantidad: { 'value': row.cantidad, 'key': row.cantidad, 'visible':true },
        Valor: { 'value': row.valor, 'key': row.valor, 'visible':true },
        baseImpuesto: { 'value': row.base_impuesto, 'key': row.base_impuesto, 'visible':true },
        valorImpuesto: { 'value': row.valor_impuesto, 'key': row.valor_impuesto, 'visible':true },
        descuento: { 'value': row.descuento, 'key': row.descuento, 'visible':true },
        cargo: { 'value': row.cargo, 'key': row.cargo, 'visible':true },
        tipoTarifa: { 'value': row.tipo_tarifa, 'key': row.tipo_tarifa, 'visible':true }
      };
    });
    this.setState({ dataDatagridFactura: rows });

  }

  builddetailInterfaceDocumentosAsociados = (data) => {
    let linkModulePrint, linkModule,rows2,  rows = data.map(row => {
      if(row.documento_nombre==='PEDIDO'){
        linkModule = '/home/pedido/' + row.pedido_codigo + '/edit';
      }
      return {
        DocumentoAsociado: { 'value': row.pedido_codigo, 'key': row.pedido_codigo, 'visible':true,  'link':true, 'href':linkModule},
        DocumentoAsociadoNombre: { 'value': row.documento_nombre, 'key': row.documento_nombre, 'visible':true },
        Imprimir: { 'value': row.pedido_codigo, 'key': row.pedido_codigo,'visible':true }
      };
    });

   linkModulePrint = 'https://catalogo-vpfe.dian.gov.co/document/Searchqr?documentkey=' + this.state.fields.facturaCufe;    
    rows2={
      DocumentoAsociado: { 'value': this.state.fields.facturaCodigo, 'key': this.state.fields.facturaCodigo, 'visible':true },
      DocumentoAsociadoNombre: { 'value': 'FACTURA DIAN', 'key': 'FACTURA DIAN', 'visible':true },
      Imprimir: { 'value': this.state.fields.facturaCodigo, 'key': this.state.fields.facturaCodigo,'visible':true, 'link':true, 'href':linkModulePrint}    
    };
    
    this.setState({ dataDatagridFacturaDocumentosAsociados: rows});
    this.state.dataDatagridFacturaDocumentosAsociados.push(rows2);
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }
 
  calcularSubtotal = () => {
    var facturaSubtotal=this.state.fields.facturaValor-((this.state.fields.facturaTotalImpuesto+this.state.fields.facturaCargo)-(this.state.fields.facturaDescuento));
    this.setState({ facturaSubtotal });
  }

  handleDatagrid = (data) => {
        return null;    
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  handleAuto = (autoCompleteValue) => {
    this.setState({ colorAutoComplete: autoCompleteValue });
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="facturaCodigo"
              className={this.props.classes.tittleLabelForm}>Factura
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.facturaCodigo !== null) ?
                (<Chip
                  label={this.state.fields["facturaCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>      

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaResolucion" label="Resolucion" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaResolucion"] || undefined}
                InputLabelProps={{ shrink: true }} disabled
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="clienteCodigo"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteNombre1']}
                primaryField='clienteCodigo'
                aliases={['ID', 'Nombre1']}
                searchField='clienteNombre1'
                placeHolder='Seleccionar Cliente'
                value={this.state.fields["clienteCodigo"]} 
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="sucursalCodigo"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre1']}
                searchField='sucursalNombre'
                placeHolder='Seleccionar Sucursal'
                value={this.state.fields["sucursalCodigo"]} 
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaFecha"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaFecha"]  || undefined}
                type="date"
                label="Fecha"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaFechaVencimiento"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaFechaVencimiento"]}
                type="date"
                label="Fecha Vencimiento"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="comercialCodigo"
                tableName='Comercial'
                fields={['comercialCodigo', 'comercialNombreCompleto']}
                primaryField='comercialCodigo'
                aliases={['ID', 'Nombre']}
                searchField='comercialNombreCompleto'
                placeHolder='Seleccionar Comercial'
                value={this.state.fields["comercialCodigo"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>           
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  namelabel="Forma Pago"
                  id="forpagCodigo"
                  namelineInput="formapago"
                  fieldVisible="forpagNombre"
                  fieldCode="forpagCodigo"
                  Table="Formapago"
                  Vista="true"
                  Where="true"
                  useChildren={false}
                  onChange={this.handleChange}
                  value={this.state.fields["forpagCodigo"]}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaDocumentoSoporte1" label="Documento Soporte" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaDocumentoSoporte1"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaCufe" label="Cufe" variant="outlined"
              fullWidth margin="normal" type="text"
              InputLabelProps={{ shrink: true }} multiline
              rows={4} onChange={this.handleChange}
              value={this.state.fields["facturaCufe"] || undefined}
              disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>  
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaFechaDian"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaFechaDian"]}
                type="text"
                label="Tiempo Envio Dian"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Grid>  
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaFirmaDigitalDian" label="Firma Digital Dian" variant="outlined"
              fullWidth margin="normal" type="text"
              InputLabelProps={{ shrink: true }} multiline
              rows={4} onChange={this.handleChange}
              value={this.state.fields["facturaFirmaDigitalDian"] || undefined}
              disabled
              />
            </Grid> 

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="facturaEnviarALaDian"
                useChildren={true}
                value={this.state.fields["facturaEnviarALaDian"]}
                onChange={this.handleChange}
                namelabel="Enviar a la DIAN (*)"
                disabled
              >
                <option value="true">SI</option>
                <option value="false">NO</option>
              </PSelect>
            </Grid>     
          </Grid>


          <Grid container spacing={4}> 
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="facturaContabilidad"
                  useChildren={true}
                  value={this.state.fields["facturaContabilidad"]}
                  onChange={this.handleChange}
                  namelabel="Contabilidad (*)"
                >
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </PSelect>
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="facturaTipoFactura"
                  useChildren={true}
                  value={this.state.fields["facturaTipoFactura"]}
                  onChange={this.handleChange}
                  namelabel="Tipo Factura (*)"
                  disabled
                >
                  <option value="BODEHOGAR">BODEHOGAR</option>
                  <option value="NORMAL">NORMAL</option>
                </PSelect>
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="facturaEstado"
                  useChildren={true}
                  value={this.state.fields["facturaEstado"]}
                  onChange={this.handleChange}
                  namelabel="Estado (*)"
                  disabled
                >
                  <option value="INACTIVO">INACTIVO</option>
                  <option value="ACTIVO">ACTIVO</option>
                </PSelect>
              </Grid>            
          </Grid>

          <Grid container spacing={4}> 
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="facturaSubtotal" label="Subtotal (*)" type="number"
                  fullWidth margin="normal" variant="outlined"
                  value={this.state.facturaSubtotal}
                  InputLabelProps={{ shrink: true }}
                  disabled
                /></Grid>              
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="facturaDescuento" label="Descuento (*)" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["facturaDescuento"]}
                  InputLabelProps={{ shrink: true }}
                  disabled
                /></Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="facturaCargo" label="Cargo (*)" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["facturaCargo"]}
                  InputLabelProps={{ shrink: true }}
                  disabled
                /></Grid>                        
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaTotalImpuestoResta" label="Impuesto Resta (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaTotalImpuestoResta"]}
                InputLabelProps={{ shrink: true }}
                disabled
              /></Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaTotalImpuestoSuma" label="Impuesto Suma (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaTotalImpuestoSuma"]}
                InputLabelProps={{ shrink: true }}
                disabled
              /></Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaTotalImpuesto" label="Total Impuesto (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaTotalImpuesto"]}
                InputLabelProps={{ shrink: true }}
                disabled
              /></Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaValor" label="Valor (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaValor"]}
                InputLabelProps={{ shrink: true }}
                disabled
              /></Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="facturaObservacion" label="Observacion" variant="outlined"
              fullWidth margin="normal" type="text"
              InputLabelProps={{ shrink: true }} multiline
              rows={4} onChange={this.handleChange}
              value={this.state.fields["facturaObservacion"] || undefined}
              />
            </Grid> 
          </Grid>


          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>
         
          <CustomizedExpansionPanel tittle="Detalle Factura">
            <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridFactura} />
          </CustomizedExpansionPanel>

          <CustomizedExpansionPanel tittle="Documentos Asociados">
            <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridFacturaDocumentosAsociados} />
          </CustomizedExpansionPanel>

        </form>
        <div>
          <Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          edit={(this.state.fields.facturaCodigo !== null) ? true : false}
          primaryFields={['facturaCodigo']}
        /></div>
      </Paper>
    );
  }
}

Factura.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Factura)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;