import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import PSelect from '../../../GeneralUI/PSelect'
import ListItemText from '@material-ui/core/ListItemText';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { errorSetter } from '../../../../Libs';


class Detallepedidocumplido extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    errorForm: false,
    errorMessage: '',
    fields: {
      Tercero: { 'value': null, 'key': null,'visible':true  },     
      tipoTercero: { 'value': 'AUXILIAR', 'key': 'AUXILIAR','visible':true  },
      cargueNacional: { 'value': 'NO', 'key': 'NO','visible':true  },

      descargueNacional: { 'value': 'NO', 'key': 'NO','visible':true  },
      cargueUrbano: { 'value': 'NO', 'key': 'NO','visible':true  },
      descargueUrbano: { 'value': 'NO', 'key': 'NO','visible':true  },

      Empaque: { 'value': 'NO', 'key': 'NO','visible':true  },
      movimientoEspeciales: { 'value': 'NO', 'key': 'NO','visible':true  },
      movimientoBodega: { 'value': 'NO', 'key': 'NO','visible':true  },

      Ceroestres: { 'value': 'NO', 'key': 'NO','visible':true  },
      Huacal: { 'value': 'NO', 'key': 'NO','visible':true  },
      ValorLiquidacion: { 'value': 0, 'key': 0,'visible':true  }
    },
    formValidations: {
      view: ['Tercero', 'tipoTercero', 'cargueNacional','cargueUrbano','descargueNacional','descargueUrbano','Empaque','movimientoEspeciales','movimientoBodega', 'Ceroestres', 'Huacal', 'ValorLiquidacion']
    },
    formErrors: {
      Tercero: {},
      tipoTercero: {},
      cargueNacional: {},

      descargueNacional: {},
      cargueUrbano: {},
      descargueUrbano: {},

      Empaque: {},
      movimientoEspeciales: {},
      movimientoBodega: {},

      Ceroestres: {},
      Huacal: {},
      ValorLiquidacion: {},
    }
  };


  componentDidMount = () => {
    console.log(this.props.editData);
    if (this.props.editData) {
      this.fillForm(this.props.editData, this.props.setIndex)
    }
    if (this.props.datosHeader) {
      this.setState(this.props.datosHeader);
    }
  }

  fillForm = (data, index) => {
    let { fields } = this.state;

    fields["Tercero"] = ({ 'value': data[index].Tercero.value ? data[index].Tercero.value : null, 'key': data[index].Tercero.key,'visible':true });
    fields["tipoTercero"] = ({ 'value': data[index].tipoTercero.value, 'key': data[index].tipoTercero.key,'visible':true });
    fields["cargueNacional"] = ({ 'value': data[index].cargueNacional.value, 'key': data[index].cargueNacional.key ,'visible':true});

    fields["descargueNacional"] = ({ 'value': data[index].descargueNacional.value, 'key': data[index].descargueNacional.key ,'visible':true});
    fields["cargueUrbano"] = ({ 'value': data[index].cargueUrbano.value, 'key': data[index].cargueUrbano.key ,'visible':true});
    fields["descargueUrbano"] = ({ 'value': data[index].descargueUrbano.value, 'key': data[index].descargueUrbano.key ,'visible':true});

    fields["Empaque"] = ({ 'value': data[index].Empaque.value, 'key': data[index].Empaque.key,'visible':true });
    fields["movimientoEspeciales"] = ({ 'value': data[index].movimientoEspeciales.value, 'key': data[index].movimientoEspeciales.key,'visible':true });
    fields["movimientoBodega"] = ({ 'value': data[index].movimientoBodega.value, 'key': data[index].movimientoBodega.key,'visible':true });  

    fields["Ceroestres"] = ({ 'value': data[index].Ceroestres.value, 'key': data[index].Ceroestres.key,'visible':true });
    fields["Huacal"] = ({ 'value': data[index].Huacal.value, 'key': data[index].Huacal.key,'visible':true });
    fields["ValorLiquidacion"] = ({ 'value': data[index].ValorLiquidacion.value, 'key': data[index].ValorLiquidacion.key,'visible':true });  
    this.setState({ fields }); 
  }

  handleChange = (event) => {
    let { fields } = this.state;

    fields[event.target.id].value =event.target.value;
    fields[event.target.id].key =event.target.hasOwnProperty('text') ? event.target.text : event.target.value;    
        
    this.setState({ fields });

  }

 
  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }

  setData = async () => {
    let { fields } = this.state; 
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);
    this.setState({ formErrors: validations.errors,fields });

    if (validations.isValid && !this.state.errorForm) {
      this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);
    }
  }

  render = () => {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Detalle de Auxiliares" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
              {(this.state.errorForm) ?
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem> : ''
              }
            </List>
          </Grid>

            <Grid container spacing={2}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <AutoComplete
                    id="Tercero"
                    tableName='Tercero'
                    fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                    primaryField='terceroCodigo'
                    aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                    searchField='terceroRazonSocial'
                    placeHolder='Seleccionar Tercero'
                    onSelect={this.handleChange}
                    value={this.state.fields["Tercero"].value}
                    size='xx'
                  />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <PSelect
                    id="tipoTercero"
                    useChildren={true}
                    value={this.state.fields["tipoTercero"].value}
                    onChange={this.handleChange}
                    namelabel="Tipo Tercero"
                  >
                    <option value="CONDUCTOR">CONDUCTOR</option>
                    <option value="AUXILIAR">AUXILIAR</option>
                  </PSelect>
                </Grid>    
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <PSelect
                    id="cargueUrbano"
                    useChildren={true}
                    value={this.state.fields["cargueUrbano"].value}
                    onChange={this.handleChange}
                    namelabel="Cargue Urbano"
                  >
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </PSelect>
                </Grid>      

          </Grid>

          <Grid container spacing={2}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <PSelect
                    id="descargueUrbano"
                    useChildren={true}
                    value={this.state.fields["descargueUrbano"].value}
                    onChange={this.handleChange}
                    namelabel="Descargue Urbano"
                  >
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </PSelect>
                </Grid>  
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <PSelect
                    id="cargueNacional"
                    useChildren={true}
                    value={this.state.fields["cargueNacional"].value}
                    onChange={this.handleChange}
                    namelabel="Cargue Nacional"
                  >
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </PSelect>
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <PSelect
                    id="descargueNacional"
                    useChildren={true}
                    value={this.state.fields["descargueNacional"].value}
                    onChange={this.handleChange}
                    namelabel="Descargue Nacional"
                  >
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </PSelect>
                </Grid>
          </Grid>

          <Grid container spacing={2}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <PSelect
                    id="Empaque"
                    useChildren={true}
                    value={this.state.fields["Empaque"].value}
                    onChange={this.handleChange}
                    namelabel="Empaque"
                  >
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </PSelect>
                </Grid>  
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <PSelect
                    id="movimientoEspeciales"
                    useChildren={true}
                    value={this.state.fields["movimientoEspeciales"].value}
                    onChange={this.handleChange}
                    namelabel="Movimiento Especiales"
                  >
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </PSelect>
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <PSelect
                    id="movimientoBodega"
                    useChildren={true}
                    value={this.state.fields["movimientoBodega"].value}
                    onChange={this.handleChange}
                    namelabel="Movimiento Bodega"
                  >
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </PSelect>
                </Grid>
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="Ceroestres"
                  useChildren={true}
                  value={this.state.fields["Ceroestres"].value}
                  onChange={this.handleChange}
                  namelabel="Cero Estres"
                >
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </PSelect>
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="Huacal"
                  useChildren={true}
                  value={this.state.fields["Huacal"].value}
                  onChange={this.handleChange}
                  namelabel="Huacal"
                >
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </PSelect>
              </Grid>

              <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="ValorLiquidacion" label="Valor Liquidacion Manual" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["ValorLiquidacion"].value}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} >
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>

            </Grid>
          </Grid>


        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detallepedidocumplido);