import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import PSelect from '../../../GeneralUI/PSelect'
import ListItemText from '@material-ui/core/ListItemText';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { saveCustom } from './../../../../Actions/General'
import { errorSetter } from '../../../../Libs';

class Detallemovimientoinventario extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    errorForm: false,
    errorMessage: '',
    fields: {
      bodegaCodigo: { 'value': null, 'key': null ,'visible':true},
      ubicacionCodigo: { 'value': null, 'key': null,'visible':true },
      productoCodigo: { 'value': null, 'key': null,'visible':true },
      Cantidad: { 'value': null, 'key': null,'visible':true },
      costoUnitario: { 'value': null, 'key': null,'visible':true },
      Lote: { 'value': null, 'key': null,'visible':true },
      Estado: { 'value': null, 'key': null,'visible':true },
      Cubicaje: { 'value': null, 'key': null,'visible':true }, 
      ValorDeclarado: { 'value': null, 'key': null,'visible':true }, 
      ValorSeguro: { 'value': null, 'key': null,'visible':true }, 
      DuracionBodega: { 'value': null, 'key': null,'visible':true }, 
      AliasBodega: { 'value': null, 'key': null,'visible':true }, 
      FechaIngresoBodega: { 'value': null, 'key': null,'visible':true },
    },
    formValidations: {
      view: ['bodegaCodigo', 'ubicacionCodigo', 'productoCodigo',  'Cantidad', 'costoUnitario']
    },
    formErrors: {
      bodegaCodigo: {},
      ubicacionCodigo: {},
      productoCodigo: {},
      Cantidad: {},
      costoUnitario: {},
    }
  };


  componentDidMount = () => {
    console.log(this.props.editData);
    if (this.props.editData) {
      this.fillForm(this.props.editData, this.props.setIndex)
    }
    if (this.props.datosHeader) {
      this.setState(this.props.datosHeader);
    }
  }

  fillForm = (data, index) => {
    let { fields } = this.state;

    fields["bodegaCodigo"] = ({ 'value': data[index].bodegaCodigo.value, 'key': data[index].bodegaCodigo.key,'visible':true });
    fields["ubicacionCodigo"] = ({ 'value': data[index].ubicacionCodigo.value, 'key': data[index].ubicacionCodigo.key,'visible':true });
    fields["productoCodigo"] = ({ 'value': data[index].productoCodigo.value ? data[index].productoCodigo.value : null, 'key': data[index].productoCodigo.key ,'visible':true});
    fields["Cantidad"] = ({ 'value': data[index].Cantidad.value, 'key': data[index].Cantidad.key,'visible':true });
    fields["costoUnitario"] = ({ 'value': data[index].costoUnitario.value, 'key': data[index].costoUnitario.key,'visible':true });
    fields["Lote"] = ({ 'value': data[index].Lote.value, 'key': data[index].Lote.key,'visible':true  }); 
    fields["Estado"] = ({ 'value': data[index].Estado.value, 'key': data[index].Estado.key,'visible':true  });

    fields["Cubicaje"] = ({ 'value': data[index].Cubicaje.value, 'key': data[index].Cubicaje.key,'visible':true });
    fields["ValorDeclarado"] = ({ 'value': data[index].ValorDeclarado.value, 'key': data[index].ValorDeclarado.key,'visible':true });
    fields["ValorSeguro"] = ({ 'value': data[index].ValorSeguro.value, 'key': data[index].ValorSeguro.key,'visible':true });
    fields["DuracionBodega"] = ({ 'value': data[index].DuracionBodega.value, 'key': data[index].DuracionBodega.key,'visible':true }); 
    fields["AliasBodega"] = ({ 'value': data[index].AliasBodega.value, 'key': data[index].AliasBodega.key,'visible':true })
    fields["FechaIngresoBodega"] = ({ 'value': data[index].FechaIngresoBodega.value, 'key': data[index].FechaIngresoBodega.key,'visible':true });
    this.setState({ fields });
  }


  handleChange = async (event) => {

    let { fields } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }


    if (event.target.type === 'time') {
      fields[event.target.id].value = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id].value = event.target.value;
    } else {
      fields[event.target.id].value = event.target.value.toUpperCase();
    }

    if (fields[event.target.id].value === '') {
      fields[event.target.id].value = null;
    }
    fields[event.target.id].key =event.target.hasOwnProperty('text') ? event.target.text : event.target.value;    this.setState({ fields });
    
    if (event.target.id === 'productoCodigo') {
      await this.getInventario(fields[event.target.id], true);
    }else{
      this.setState({ fields });
    }      
  }

  getInventario = async (field, editField) => {

    let { fields } = this.state;

    await saveCustom(field, '/processes/getInventario').then(data => {

        fields["bodegaCodigo"].value = data.bodegaCodigo;
        fields["bodegaCodigo"].key = data.bodega.bodegaNombre;

        fields["productoCodigo"].value = data.productoCodigo;
        fields["productoCodigo"].key = data.producto.productoNombre;

        fields["ubicacionCodigo"].value = data.ubicacionCodigo;
        fields["ubicacionCodigo"].key = data.ubicacion.ubicacionNombre;
    
        fields["Cantidad"].value = data.inventarioCantidad;
        fields["Cantidad"].key = data.inventarioCantidad;

        fields["costoUnitario"].value = data.inventarioCostoUnitario;
        fields["costoUnitario"].key = data.inventarioCostoUnitario;

        fields["Lote"].value = data.inventarioLote;
        fields["Lote"].key = data.inventarioLote;

        fields["Estado"].value = data.inventarioEstado;
        fields["Estado"].key = data.inventarioEstado;

        fields["Cubicaje"].value = data.inventarioCaracteristica1;
        fields["Cubicaje"].key = data.inventarioCaracteristica1;
        
        fields["ValorDeclarado"].value = data.inventarioCaracteristica2;
        fields["ValorDeclarado"].key = data.inventarioCaracteristica2;

        fields["ValorSeguro"].value = data.inventarioCaracteristica6;
        fields["ValorSeguro"].key = data.inventarioCaracteristica6;

        fields["DuracionBodega"].value = data.inventarioCaracteristica3;
        fields["DuracionBodega"].key = data.inventarioCaracteristica3;

        fields["AliasBodega"].value = data.inventarioCaracteristica4;
        fields["AliasBodega"].key = data.inventarioCaracteristica4;

        fields["FechaIngresoBodega"].value = data.inventarioCaracteristica5;
        fields["FechaIngresoBodega"].key = data.inventarioCaracteristica5;


        this.setState({ fields });
       
    }).catch(error => {
      this.setState({
        data: this.props.saveData,
        responseForm: { Message: "Error buscando inventario." + error.errorMessage, typeMessage: "error" }
      })
    });

  }

  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }

  setData = async () => {
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);
    this.setState({ formErrors: validations.errors });
   // if (validations.isValid && !this.state.errorForm) {
      this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);
    //}
  }

  render = () => {
    return (
      <Paper elevation={1} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Detalle de Productos" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
              {(this.state.errorForm) ?
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem> : ''
              }
            </List>
          </Grid>

            <Grid container spacing={2}>              
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="productoCodigo"
                  tableName='vw_inventario'
                  fields={['inventario_codigo','producto_nombre', 'bodega_nombre', 'ubicacion_nombre', 'cantidad', 'estado']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 },{ value: 'Producto', size: 3 },{ value: 'Bodega', size: 2 },{ value: 'Ubicacion', size: 2 },{ value: 'Cant', size: 1 },{ value: 'Estado', size: 3 }]}
                  size='xx'
                  primaryField='inventario_codigo'
                  searchField='producto_nombre'
                  placeHolder='Seleccionar Producto'
                  onSelect={this.handleChange}
                  where={{                    
                    cliente_codigo: this.state.clienteCodigo,
                    almacen_codigo: this.state.almacenCodigo
                  }}
                  value={this.state.fields["productoCodigo"].value}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="bodegaCodigo"
                  tableName='vw_bodega_search'
                  fields={['bodegaCodigo', 'bodegaNombre', 'almacenNombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Bodega', size: 5 }, { value: 'Almacen', size: 6}]}
                  size='xx'
                  primaryField='bodegaCodigo'
                  searchField='bodegaNombre'
                  placeHolder='Seleccionar Bodega'
                  onSelect={this.handleChange}
                  value={this.state.fields["bodegaCodigo"].value}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="ubicacionCodigo"
                  tableName='vw_ubicacion_search'
                  fields={['ubicacionCodigo', 'ubicacionNombre', 'bodegaNombre', 'almacenNombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Ubicacion', size: 3 }, { value: 'Bodega', size: 4 }, { value: 'Almacen', size: 4 }]}
                  size='xx'
                  primaryField='ubicacionCodigo'
                  searchField='ubicacionNombre'
                  placeHolder='Seleccionar Ubicacion'
                  onSelect={this.handleChange}
                  value={this.state.fields["ubicacionCodigo"].value}
                />
              </Grid>     
          </Grid>
          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="Cantidad" label="Cantidad | Area Utilizada" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["Cantidad"].value}
                  InputLabelProps={{ shrink: true }}
                  error={this.state.formErrors['Cantidad'].error}
                  helperText={this.state.formErrors['Cantidad'].errorMessage}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="costoUnitario" label="Costo Unitario" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["costoUnitario"].value}
                  InputLabelProps={{ shrink: true }}
                  error={this.state.formErrors['costoUnitario'].error}
                  helperText={this.state.formErrors['costoUnitario'].errorMessage}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField
                  id="Lote"
                  label="Lote"
                  type="text"
                  fullWidth margin="normal"
                  onChange={this.handleChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={this.state.fields["Lote"].value}
                />
              </Grid>
          </Grid>


          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="Cubicaje" label="Area Contratada" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["Cubicaje"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ValorDeclarado" label="Valor Declarado" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ValorDeclarado"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ValorSeguro" label="Valor Seguro" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ValorSeguro"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>          
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="Estado"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Estado"
                value={this.state.fields["Estado"].value}
              >
                <option value="DISPONIBLE">DISPONIBLE</option>
                <option value="AVERIADO">AVERIADO</option>
                <option value="RESERVADO">RESERVADO</option>
              </PSelect>
              </Grid>  
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="DuracionBodega" label="Duracion Bodega" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["DuracionBodega"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="AliasBodega" label="Alias Bodega" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["AliasBodega"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="FechaIngresoBodega"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FechaIngresoBodega"].value}
                type="date"
                label="Fecha Ingreso Bodega"
                InputLabelProps={{ shrink: true }}
              />
              </Grid>
          </Grid>


          <Grid container spacing={2} >
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>

            </Grid>
          </Grid>


        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detallemovimientoinventario);