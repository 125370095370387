import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import PSelect from '../../../GeneralUI/PSelect'
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { saveCustom } from './../../../../Actions/General'
import { errorSetter } from '../../../../Libs';
import Utils from '../../../../Libs/utils';


class Detallepedido extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    errorForm: false,
    errorMessage: '',
    fieldsNotData: {
      Descuento1: 0,
      Descuento2: 0,
      Descuento3: 0,
      tiptarCodigo: 0,
      valorSeguro: 0,
      tipoValorSeguro: ''
    },
    fields: {
      Sucursal: { 'value': null, 'key': null,'visible':true  },
      Tarifa: { 'value': null, 'key': null,'visible':true  },
      UnidadNegocio: { 'value': null, 'key': null,'visible':true  },
      Vendedor: { 'value': null, 'key': null,'visible':true  },
      Cantidad: { 'value': 0, 'key': 0,'visible':true  },
      VUnitario: { 'value': 0, 'key': 0,'visible':true  },
      descuentoAFacturar: { 'value': 0, 'key': 0,'visible':true  },
      VTotal: { 'value': 0, 'key': 0,'visible':true  },
      NombreBodega: { 'value': '', 'key': '','visible':true  },
      NombreBodegaAlias: { 'value': '', 'key': '','visible':true  },

      ValorSeguroBodega: { 'value': 0, 'key': 0,'visible':true  },
      ValorBodega: { 'value': 0, 'key': 0,'visible':true  },

      Cubicaje: { 'value': 0, 'key': 0,'visible':true  },
      DuracionBodega: { 'value': 0, 'key': 0,'visible':true  },
      ValorDeclarado: { 'value': 0, 'key': 0,'visible':true  },
      seguro: { 'value': 0, 'key': 0,'visible':true  },
      Estado: { 'value': null, 'key': null,'visible':false  },
      tipoPago: { 'value': null, 'key': null,'visible':false  },
      tipoTarifa: { 'value': null, 'key': null,'visible':false  },
    },

    formValidations: {
      view: ['Sucursal', 'Tarifa', 'UnidadNegocio', 'Vendedor', 'Cantidad', 'VUnitario']
    },

    formErrors: {
      Sucursal: {},
      Tarifa: {},
      UnidadNegocio: {},
      Vendedor: {},
      Cantidad: {},
      VUnitario: {},
    }
  };


  componentDidMount = () => {
    if (this.props.editData) {
      this.fillFormData(this.props.editData, this.props.setIndex)
    } else {
      this.fillForm(this.props.datosHeader)
    }
  }

  fillForm = (data) => {
    let { fields } = this.state;
    fields["Sucursal"] = ({ 'value': data.sucursalCodigo, 'key': data.sucursalCodigo,'visible':true });
    fields["Vendedor"] = ({ 'value': data.comercialCodigo, 'key': data.comercialCodigo,'visible':true  });
    this.setState({ fields });
  }

  fillFormData = async (data, index) => {

    let { fields } = this.state;

    await this.getTarifa(data[index].Tarifa, false);

    fields["Sucursal"] = ({ 'value': data[index].Sucursal.value ? data[index].Sucursal.value : null, 'key': data[index].Sucursal.key,'visible':true });
    fields["Tarifa"] = ({ 'value': data[index].Tarifa.value ? data[index].Tarifa.value : null, 'key': data[index].Tarifa.key,'visible':true  });
    fields["UnidadNegocio"] = ({ 'value': data[index].UnidadNegocio.value, 'key': data[index].UnidadNegocio.key,'visible':true  });
    fields["Vendedor"] = ({ 'value': data[index].Vendedor.value, 'key': data[index].Vendedor.key,'visible':true  });

    fields["Cantidad"] = ({ 'value': data[index].Cantidad.value, 'key': data[index].Cantidad.key,'visible':true  });
    fields["VUnitario"] = ({ 'value': data[index].VUnitario.value, 'key': data[index].VUnitario.key,'visible':true  });
    fields["descuentoAFacturar"] = ({ 'value': data[index].descuentoAFacturar.value, 'key': data[index].descuentoAFacturar.key,'visible':true  });
    fields["VTotal"] = ({ 'value': data[index].VTotal.value, 'key': data[index].VTotal.key,'visible':true  });

    fields["NombreBodega"] = ({ 'value': data[index].NombreBodega.value, 'key': data[index].NombreBodega.key,'visible':true  });
    fields["NombreBodegaAlias"] = ({ 'value': data[index].NombreBodegaAlias.value, 'key': data[index].NombreBodegaAlias.key,'visible':true  });

    fields["ValorSeguroBodega"] = ({ 'value': data[index].ValorSeguroBodega.value, 'key': data[index].ValorSeguroBodega.key,'visible':true  });
    fields["ValorBodega"] = ({ 'value': data[index].ValorBodega.value, 'key': data[index].ValorBodega.key,'visible':true  });


    fields["Cubicaje"] = ({ 'value': data[index].Cubicaje.value, 'key': data[index].Cubicaje.key,'visible':true  });

    fields["DuracionBodega"] = ({ 'value': data[index].DuracionBodega.value, 'key': data[index].DuracionBodega.key,'visible':true  });
    fields["ValorDeclarado"] = ({ 'value': data[index].ValorDeclarado.value, 'key': data[index].ValorDeclarado.key,'visible':true  });
    fields["Estado"] = ({ 'value': data[index].Estado.value, 'key': data[index].Estado.key,'visible':false  });
    fields["tipoPago"] = ({ 'value': data[index].tipoPago.value, 'key': data[index].tipoPago.key,'visible':false  });
    fields["tipoTarifa"] = ({ 'value': data[index].tipoTarifa.value, 'key': data[index].tipoTarifa.key,'visible':false  });
    
    this.setState({ fields });

  }

  handleChange = async (event) => {
    let { fields } = this.state;

    fields[event.target.id].value =event.target.value;
    fields[event.target.id].key =event.target.hasOwnProperty('text') ? event.target.text : event.target.value;    this.setState({ fields });
    if (event.target.id === 'Tarifa') {
      await this.getTarifa(fields[event.target.id], true);
    }
  }

  getTarifa = async (field, editField) => {

    let { fieldsNotData } = this.state;
    let { fields } = this.state;


    await saveCustom(field, '/processes/calculo_tarifa').then(data => {

      fieldsNotData["Descuento1"] = data.tarifaDescuento1;
      fieldsNotData["Descuento2"] = data.tarifaDescuento2;
      fieldsNotData["Descuento3"] = data.tarifaDescuento3;

      fieldsNotData["tipoValorSeguro"] = data.tarifaTipoValorSeguro;
      fieldsNotData["valorSeguro"] = data.tarifaValorSeguro;
      fieldsNotData["tiptarCodigo"] = data.tiptarCodigo;

      fields["tipoTarifa"].value=data.tiptarCodigo;
      fields["tipoTarifa"].key=data.tiptarCodigo;

      if (editField) {
        fields["Cantidad"].value=1;
        fields["Cantidad"].key=1;
 
        fields["VUnitario"].value=data.tarifaValor;
        fields["VUnitario"].key=parseFloat(data.tarifaValor).toLocaleString() ;        

        fields["VTotal"].value=data.tarifaValor;
        fields["VTotal"].key=parseFloat(data.tarifaValor).toLocaleString() ;  

        if (fieldsNotData["tiptarCodigo"] === 2 || fieldsNotData["tiptarCodigo"] === 13) {
          fields["UnidadNegocio"].value = 1;
          fields["UnidadNegocio"].key = 'BODEGA HOGAR';
        }

        if (fieldsNotData["tiptarCodigo"] === 12 || fieldsNotData["tiptarCodigo"] === 9 || fieldsNotData["tiptarCodigo"] === 10 || fieldsNotData["tiptarCodigo"] === 1) {
          fields["UnidadNegocio"].value = 2;
          fields["UnidadNegocio"].key = 'TRASTEO';
        }
        
      }

      this.setState({ fields });

      this.setState({ fieldsNotData });
    }).catch(error => {
      this.setState({
        data: this.props.saveData,
        responseForm: { Message: "Error buscando tarifa." + error.errorMessage, typeMessage: "error" }
      })
    });

  }

  calculateTotal = async (event) => { 
    let { fields } = this.state, cdias=0,pdescuento=0,dias=0,mes_adicional=0,descuento=0,cantidad=1,valor=0,total=0;
    fields[event.target.id] = ({ 'value': event.target.value, 'key': event.target.hasOwnProperty('text') ? event.target.text : event.target.value,'visible':fields[event.target.id].visible });

      if (fields.Cantidad.value && fields.VUnitario.value) {
        cantidad = parseFloat(fields["Cantidad"].value);
        valor = parseFloat(fields["VUnitario"].value);
        total = cantidad * valor;

        dias=fields["DuracionBodega"].value;

        if((dias===90 || dias===180 || dias===365) && fields.tipoPago.value==='PRIMER MES'){
          cdias=0;
          cantidad=1;
        }else if(fields.tipoPago.value==='TODO EL CONTRATO'){
          cantidad=Math.round((dias/30));
          cdias=cantidad-1;
          total = cantidad * valor;
        }
        
        if(dias>=90 && dias<180){  
          if(cdias>0){
            cdias=2;
          }               
          pdescuento=25;
          mes_adicional=2/100;
        }else if(dias>=180 && dias<360){
          if(cdias>0){
            cdias=5;
          }            
          pdescuento=50;
          mes_adicional=4/100;
        }else if(dias>=360){
          if(cdias>0){
            cdias=11; 
          }          
          pdescuento=100;
          mes_adicional=8/100;
        }

        if(fields.tipoPago.value==='PRIMER MES' || fields.tipoPago.value==='TODO EL CONTRATO'){
          
          descuento=((valor * pdescuento)/100  + (valor*cdias)*mes_adicional);
          total = total-descuento;
        }
      
        if (!isNaN(total) && total >=0) {
          fields['Cantidad'] = { 'value': String(cantidad), 'key': parseFloat(cantidad).toLocaleString(),'visible':fields['Cantidad'].visible  };

          fields['VTotal'] = { 'value': String(Math.round(total)), 'key': parseFloat(Math.round(total)).toLocaleString(),'visible':fields['VTotal'].visible  };
          fields['descuentoAFacturar'] = { 'value': String(Math.round(descuento)), 'key': parseFloat(Math.round(descuento)).toLocaleString(),'visible':fields['descuentoAFacturar'].visible  };
        }
      }
      this.setState({ fields });
  }

  validateLogicaForm = async () => {
    
    this.setDataValidation(false, '');

    await saveCustom({ value: this.props.datosHeader.pedidoCodigo }, '/processes/getpedido').then(data => {

      if(this.props.datosHeader.pedidoCodigo!==null){ 
        /*if (data.pedidoEstado === 'CONFIRMADO' &&
          Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'JENNY PAOLA ROCHA' &&
          Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'CARLOS ARTURO CARDONA GONZALEZ' &&
          Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'LUIS NABOR MARINEZ ANGULO' &&
          Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'DIANA KATHERINE GONZALEZ OSORIO' &&
          (this.state.fieldsNotData.tiptarCodigo === 2 || this.state.fieldsNotData.tiptarCodigo === 13)) {
          this.setDataValidation(true, 'El pedido esta en estado confirmado y no se puede adicionar o editar datos de bodega.');
        }*/

        if (this.state.fields.Sucursal.value === 2 &&
        (this.state.fieldsNotData.tiptarCodigo !== 13 
          && this.state.fieldsNotData.tiptarCodigo !== 2
          && this.state.fieldsNotData.tiptarCodigo !== 4 
          && this.state.fieldsNotData.tiptarCodigo !== 8
          && this.state.fieldsNotData.tiptarCodigo !== 3)) {
          this.setDataValidation(true, 'La tarifa no es valida para la sucursal CALI SUR.');
        }

        if((data.pedidoEstado === 'CONFIRMADO' || data.pedidoEstado === 'FACTURADO' || data.pedidoEstado === 'DESPACHADO') && (this.state.fieldsNotData.tiptarCodigo===13 || this.state.fieldsNotData.tiptarCodigo===2) && 
        (this.state.fields.tipoPago.value == null || !String(this.state.fields.tipoPago.value).trim()) ){
          this.setDataValidation(true, 'Debe diligenciar el tipo de pago para esta tarifa.');
        }

      }



      if(this.state.fields.UnidadNegocio.value===3 && 
      Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'JENNY PAOLA ROCHA' &&
      Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'CARLOS ARTURO CARDONA GONZALEZ' &&
      Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'LUIS NABOR MARINEZ ANGULO' &&
      Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'ALEX BONILLA' ){
        this.setDataValidation(true, 'La unidad de negocio SUPER FAST no está permitida para tu usuario.');
      }

    }).catch(error => {
      this.setDataValidation(true, 'Error inesperado.' + error);
    });
  }

  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }


  setData = async () => {
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);
    this.setState({ formErrors: validations.errors });
    await this.validateLogicaForm();

    if (validations.isValid && !this.state.errorForm) {
      let seguro = this.state.fields.seguro, fields_seguro = {}, valor_seguro = 0,
        tarifa_seguro_codigo = 0, tarifa_seguro_nombre = '';

      delete this.state.fields.seguro;

      this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);

      if ((seguro.value === 'SI' && this.state.fields.ValorDeclarado.value > 0 &&
        this.state.fieldsNotData.valorSeguro > 0 && this.state.fieldsNotData.tipoValorSeguro === 'PORCENTAJE' &&
        (this.state.fieldsNotData.tiptarCodigo === 10 || this.state.fieldsNotData.tiptarCodigo === 9
          || this.state.fieldsNotData.tiptarCodigo === 2 || this.state.fieldsNotData.tiptarCodigo === 13
          || this.state.fieldsNotData.tiptarCodigo === 12))) {


        if (this.state.fieldsNotData.tiptarCodigo === 2 || this.state.fieldsNotData.tiptarCodigo === 13) {
          tarifa_seguro_codigo = 1;
          tarifa_seguro_nombre = 'SEGURO BODEGA';
        }

        if (this.state.fieldsNotData.tiptarCodigo === 9 || this.state.fieldsNotData.tiptarCodigo === 12) {
          tarifa_seguro_codigo = 807;
          tarifa_seguro_nombre = 'SEGURO DE TRANSPORTE';
        }

        if (this.state.fieldsNotData.tiptarCodigo === 10) {
          tarifa_seguro_codigo = 843;
          tarifa_seguro_nombre = 'SEGURO DE TRANSPORTE INTERNACIONAL';
        }

        if (this.state.fieldsNotData.tipoValorSeguro === 'PORCENTAJE') {
          valor_seguro = (this.state.fields.ValorDeclarado.value * this.state.fieldsNotData.valorSeguro) / 100;
        } else {
          valor_seguro = this.state.fieldsNotData.valorSeguro;
        }

        fields_seguro = {
          Sucursal: { 'value': this.state.fields.Sucursal.value, 'key': this.state.fields.Sucursal.key,'visible':true  },
          Tarifa: { 'value': tarifa_seguro_codigo, 'key': tarifa_seguro_nombre,'visible':true  },
          UnidadNegocio: { 'value': this.state.fields.UnidadNegocio.value, 'key': this.state.fields.UnidadNegocio.key,'visible':true  },
          Vendedor: { 'value': this.state.fields.Vendedor.value, 'key': this.state.fields.Vendedor.key,'visible':true  },

          Cantidad: { 'value': 1, 'key': 1,'visible':true  },
          VUnitario: { 'value': valor_seguro, 'key': parseFloat(valor_seguro).toLocaleString()  ,'visible':true  },
          descuentoAFacturar: { 'value': 0, 'key': 0,'visible':true  },
          VTotal: { 'value': valor_seguro, 'key': parseFloat(valor_seguro).toLocaleString() ,'visible':true  },

          

          NombreBodega: { 'value': this.state.fields.NombreBodega.value, 'key': this.state.fields.NombreBodega.key,'visible':true  },
          NombreBodegaAlias: { 'value': this.state.fields.NombreBodegaAlias.value, 'key': this.state.fields.NombreBodegaAlias.key,'visible':true  },
          
          ValorSeguroBodega: { 'value': this.state.fields.ValorSeguroBodega.value, 'key': this.state.fields.ValorSeguroBodega.key,'visible':true  },
          ValorBodega: { 'value': this.state.fields.ValorBodega.value, 'key': this.state.fields.ValorBodega.key,'visible':true  },

          
          
          Cubicaje: { 'value': 0, 'key': 0,'visible':true  },
          DuracionBodega: { 'value': 0, 'key': 0,'visible':true  },

          ValorDeclarado: { 'value': this.state.fields.ValorDeclarado.value, 'key': parseFloat(this.state.fields.ValorDeclarado.value).toLocaleString() ,'visible':true },
          Estado: { 'value': this.state.fields.Estado.value, 'key': this.state.fields.Estado.key,'visible':false  },
          tipoPago: { 'value': this.state.fields.tipoPago.value, 'key': this.state.fields.tipoPago.key,'visible':false  },
          tipoTarifa: { 'value': this.state.fields.tipoTarifa.value, 'key': this.state.fields.tipoTarifa.key,'visible':false  }

        };
        this.props.setData(fields_seguro, null);
      }

    }
  }

  render = () => {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Detalle de Pedido" classes={{ primary: this.props.classes.titlelabelformwhite }} />


              </ListItem>
              {(this.state.errorForm) ?
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem> : ''
              }
            </List>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="Sucursal"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre']}
                searchField='sucursalNombre'
                placeHolder='Seleccionar Sucursal'
                onSelect={this.handleChange}
                value={this.state.fields["Sucursal"].value}
                formErrors={this.state.formErrors['Sucursal']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Vendedor (*)"
                id="Vendedor"
                namelineInput="Vendedor"
                fieldVisible="comercial_nombre_completo"
                fieldCode="comercial_codigo"
                Table="comercial"
                isView={true}
                where={{
                  comercial_estado: 'ACTIVO'
                }}
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["Vendedor"].value}
                formErrors={this.state.formErrors['Vendedor']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="Tarifa"
                tableName='Tarifa'
                fields={['tarifaCodigo', 'tarifaNombre']}
                aliases={[{ value: 'ID', size: 2 }, { value: 'Nombre', size: 10 }]}
                size='XX'
                paperLeft='400'
                where={{
                  tarifaEstado: 'ACTIVO'
                }}
                primaryField='tarifaCodigo'
                searchField='tarifaNombre'
                placeHolder='Seleccionar Tarifa'
                onSelect={this.handleChange}
                value={this.state.fields["Tarifa"].value}
                formErrors={this.state.formErrors['Tarifa']}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Unidad Negocio (*)"
                id="UnidadNegocio"
                namelineInput="unidadNegocio"
                fieldVisible="unineg_nombre"
                fieldCode="unineg_codigo"
                Table="unidad_negocio"
                isView={true}
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["UnidadNegocio"].value}
                formErrors={this.state.formErrors['UnidadNegocio']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="seguro"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Seguro"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="ValorDeclarado"
                label="Valor Declarado"
                type="text"
                onChange={this.handleChange}
                fullWidth margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["ValorDeclarado"].value}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="NombreBodega"
                label="Nombre Bodega"
                type="text"
                fullWidth margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["NombreBodega"].value}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="NombreBodegaAlias"
                label="Alias Bodega"
                type="text"
                fullWidth margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["NombreBodegaAlias"].value}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="DuracionBodega"
                label="DuracionBodega"
                type="text"
                fullWidth margin="normal"                
                onChange={this.calculateTotal}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["DuracionBodega"].value}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Cubicaje"
                label="Cubicaje"
                type="text"
                fullWidth margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["Cubicaje"].value}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="ValorSeguroBodega"
                label="ImpresionContrato Valor Seguro Bodega"
                type="text"
                fullWidth margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["ValorSeguroBodega"].value}
                
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="ValorBodega"
                label="ImpresionContrato Valor Bodega"
                type="text"
                fullWidth margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["ValorBodega"].value}
                
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Descuento1"
                label="Descuento Autorizado 1"
                type="text"
                fullWidth margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fieldsNotData["Descuento1"]}
                disabled
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Descuento2"
                label="Descuento Autorizado 2"
                type="text"
                fullWidth margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fieldsNotData["Descuento2"]}
                disabled
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Descuento3"
                label="Descuento Autorizado 3"
                type="text"
                fullWidth margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fieldsNotData["Descuento3"]}
                disabled
              />
            </Grid>         
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="tipoPago"
                useChildren={true}
                onChange={this.calculateTotal}
                namelabel="Paga:"
                value={this.state.fields["tipoPago"].value}
              >
                <option value="PRIMER MES">PRIMER MES</option>
                <option value="TODO EL CONTRATO">TODO EL CONTRATO</option>
              </PSelect>
            </Grid>            
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Cantidad"
                label="Cantidad Servicio(*)"
                type="number"
                fullWidth margin="normal"
                variant="outlined"
                onChange={this.calculateTotal}
                value={this.state.fields["Cantidad"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['Cantidad'].error}
                helperText={this.state.formErrors['Cantidad'].errorMessage}
              />
            </Grid>         
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="VUnitario"
                label="Valor Unitario (*)"
                type="number"
                fullWidth margin="normal"
                variant="outlined"
                onChange={this.calculateTotal}
                value={this.state.fields["VUnitario"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['VUnitario'].error}
                helperText={this.state.formErrors['VUnitario'].errorMessage}
              />
            </Grid>           
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField
                  id="descuentoAFacturar"
                  label="Descuento A Facturar"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  InputLabelProps={{ shrink: true }}
                  value={this.state.fields["descuentoAFacturar"].value}   
                  disabled             
                />
              </Grid> 

              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField
                  id="VTotal"
                  label="Valor Total (*)"
                  type="number"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  InputLabelProps={{ shrink: true }}
                  value={this.state.fields["VTotal"].value}
                  disabled
                />
              </Grid>
            </Grid>

            {(this.state.errorForm) ?
              <Grid container spacing={2} >
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem>
                </Grid> : ''
            }

          <Grid container spacing={2} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>

        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detallepedido);