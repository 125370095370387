import React, {
  Component
} from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { handlerPselect } from './../../Actions/General'
import TextField from '@material-ui/core/TextField';

class PSelect extends Component {

  state = {
    valor: '',
    labelWidth: 0,
    data: { 'rows': [] },
  };

  componentDidMount() {
    this.setState({
      labelWidth: 200,
      id: this.props.id,
    });
    if (!this.props.useChildren) {
      this.fillselect();
    }
  }


  fillselect = () => {
    var fields = [];
    fields.push(this.props.fieldCode);
    fields.push(this.props.fieldVisible);

    handlerPselect({
      fields,
      isView: this.props.isView,
      filters: this.props.where,
    }, this.props.Table).then(data => {
      this.setState({
        data: data
      })

    }).catch(error => {
      console.log('error api pselect:', error)
    });
  }

  handleChange = event => {
    var e = document.getElementById(this.props.id);
    let data = {
      target: {
        'id': this.props.id,
        'value': e.value,
        'text': e.options[e.selectedIndex].text
      }
    };
    this.props.onChange(data);
  };

  handleBlur = event => {
    if (this.props.onBlur) {
      var e = document.getElementById(this.props.id);
      let data = {
        target: {
          'id': this.props.id,
          'value': e.value,
          'text': e.options[e.selectedIndex].text
        }
      };
      this.props.onBlur(data);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((nextState.data !== this.state.data) || (nextProps !== this.props)) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <FormControl variant="outlined" fullWidth >

        <TextField
          id={this.props.id}
          select
          label={this.props.namelabel}
          value={this.props.value || undefined}
          disabled={this.props.disabled || undefined}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          SelectProps={{
            native: true,
            MenuProps: {
              width: this.state.labelWidth,
            },
          }}
          margin="normal"
          variant="outlined"
          error={this.props.formErrors && this.props.formErrors.error ? this.props.formErrors.error : null}
          helperText={this.props.formErrors && this.props.formErrors.errorMessage ? this.props.formErrors.errorMessage : null}
          InputLabelProps={{ shrink: true }}
          inputRef={(input) => { this[this.props.id] = input; }}
        >

          <option value="" />
          {
            this.state.data.rows.length > 0 && !this.props.useChildren ? (
              this.state.data.rows.map((item, index, array) => {
                return (
                  <option value={Object.values(item)[0]} key={index} >
                    {Object.values(item)[1]}
                  </option>
                )
              })
            ) :
              (
                this.props.children
              )
          }
        </TextField>
      </FormControl>
    );
  }
}

PSelect.propTypes = {
  namelabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  useChildren: PropTypes.bool.isRequired,
  formErrors: PropTypes.object,
  onBlur: PropTypes.func
};

export default PSelect;