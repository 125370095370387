import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { getDocument } from '../../../../Actions/General';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { combineStyles } from '../../../../Libs';

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class InformeEncuestaBodega extends Component {

  state = {
    data: [],
    fields: {

      FECHA_INICIAL_SERVICIO: null,
      FECHA_FINAL_SERVICIO: null,


      COMERCIAL_CODIGO: null,
      SUCURSAL_CODIGO: null,
    },
    paramsInforme: '/imprimir_informe/encuesta_bodega/informe_encuesta_bodega?FECHA_INICIAL_SERVICIO=' + null + '&FECHA_FINAL_SERVICIO=' + null,
    responseForm: { Message: '', typeMessage: '' },
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'pedidoCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    let params = '';
    fields[event.target.id] = event.target.value;
    this.setState({ fields });

    params = '/imprimir_informe/encuesta_bodega/informe_encuesta_bodega?FECHA_INICIAL_SERVICIO=' + this.state.fields.FECHA_INICIAL_SERVICIO + '&FECHA_FINAL_SERVICIO=' + this.state.fields.FECHA_FINAL_SERVICIO;
    this.setState({ paramsInforme: params });
  }

  save = (response) => {
    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }

    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Informe Encuesta Bodega
            </InputLabel>
          </Grid>


          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FECHA_INICIAL_SERVICIO"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FECHA_INICIAL_SERVICIO"]}
                type="date"
                label="FechaServicio Inicial"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FECHA_FINAL_SERVICIO"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FECHA_FINAL_SERVICIO"]}
                type="date"
                label="FechaServicio Final"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </form>


        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='informe_encuesta_bodega'
            paramsInforme={this.state.paramsInforme}
            save={this.save}
            primaryFields={['pedidoCodigo']}
            url='/imprimir_informe/encuesta_bodega/informe_encuesta_bodega'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

}



InformeEncuestaBodega.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(InformeEncuestaBodega)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;